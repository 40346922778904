import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MainLayout from './components/general/MainLayout';
import { GlobalProvider } from './context/globalContext';
import './i18n';
import ConstructionSite from './pages/construction/Site';


const App: React.FC = () => {
  return (
    <GlobalProvider>
      <Router>
        <MainLayout>
          <Routes>
            <Route path="/" element={<ConstructionSite />} />
          </Routes>
        </MainLayout>
      </Router>
    </GlobalProvider>
  );
};

export default App;
