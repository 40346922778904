import Button from "../../components/general/Button";
import { useTranslation } from 'react-i18next';
import Logo2Icon from "../../components/icons/Logo2Icon";
import InstagramIcon from "../../components/icons/InstagramIcon";
import { Link } from "react-router-dom";

const ConstructionSite = () => {
  const { t } = useTranslation();
  const instagramLink = 'https://www.instagram.com/lescharmes_dupetitpre';
  return (
    <div>
      <div className="h-screen relative flex justify-center items-center">
        <img className="absolute w-full h-full" src="/images/construction_site.webp" alt="Home" style={{
          objectFit: 'cover',
        }} />
        <div className="bg-background-primary/95 w-full z-10 text-primary flex flex-col justify-center text-center font-[Degular] items-center gap-8 max-lg:gap-4 font-semibold tracking-wider 
        py-[70px] max-lg:py-[35px]">
        <Logo2Icon className="max-lg:max-h-8" width="auto" height="60" color="#b39180" />
          <div className="text-3xl max-lg:text-lg">On peaufine les détails pour mieux vous<br/>accueillir. Le site revient bientôt, encore<br/>plus inspirant!</div>
          <Link target="_blank" to={instagramLink} className="p-4 py-4 max-lg:py-1 w-fit border-y-2 border-primary text-3xl max-lg:text-lg flex items-center gap-3">
            <InstagramIcon className="h-10 max-lg:h-5" width="auto" color="#b39180" />
            <span>lescharmes_dupetitpre</span></Link>
        </div>
      </div>
    </div>
  );
};

export default ConstructionSite;
//830/3840
//1280