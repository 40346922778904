import React, { createContext, useState, useEffect, ReactNode, useContext } from 'react';

// Define the context type
interface GlobalContextType {
  global: string;
  setGlobal: (lang: string) => void;
}

// Create the context
const GlobalContext = createContext<GlobalContextType | undefined>(undefined);

// Custom hook for using global context
export const useGlobal = () => {
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error("useGlobal must be used within a GlobalProvider");
  }
  return context;
};

// Provider component
export const GlobalProvider = ({ children }: { children: ReactNode }) => {
  const [global, setGlobal] = useState<string>(() => {
    // Retrieve the global from localStorage (default to 'en' if not set)
    const storedGlobal = localStorage.getItem('appGlobal');
    return storedGlobal || 'en';
  });

  // Update localStorage whenever the global changes
  useEffect(() => {
    localStorage.setItem('appGlobal', global);
  }, [global]);

  return (
    <GlobalContext.Provider value={{ global, setGlobal }}>
      {children}
    </GlobalContext.Provider>
  );
};
