import React from 'react';
import Header from './Header';
import Footer from './Footer';

type MainLayoutProps = {
    children: React.ReactNode;
}

const MainLayout = ({ children }: MainLayoutProps) => {
  return (
    <>
      {/* <Header /> */}
      <main className='pat-44'>{children}</main>
      {/* <Footer /> */}
    </>
  );
};

export default MainLayout;
